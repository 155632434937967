import styled, { css } from "styled-components";
import { Style } from "../../styles";

const PageContent = styled.div<{ fullWidth?: boolean, backgroundColor?: string, noAfter?: boolean }>`
  height: 100%;
  padding: 100px 32px 55px 32px;
	width: 100%;
	max-width: 1200px;
  margin: 0 auto;

  .has-banner & {
    padding-top: 130px;
  }

  &.is-track, &.is-accountant, &.is-transactions, &.is-payments {
    padding-top:  60px;
  }
	
	${props => props.fullWidth && css`
		max-width: initial;
	`}

	${(props => props.backgroundColor && css`
		background-color: ${props.backgroundColor};
	`)}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 120px;
    padding-bottom: 55px;
  }

  &::after {
    content: "";
    display: block;
    height: 50px;
    width: 100%;

    ${props => props.noAfter && css`
      display: none;
    
    `}
  }
`

export default PageContent